import Vue from "vue";
import VueRouter from "vue-router";
import { ysfLogin } from "../http/modules/login";
import { Toast, Dialog, Notify } from "vant";
Vue.use(VueRouter);

//保存原型对象的Push
let originPush = VueRouter.prototype.push;
let originReplace = VueRouter.prototype.replace;
//重写push方法
VueRouter.prototype.push = function (location, res, rej) {
  if (res && rej) {
    originPush.call(this, location, res, rej);
  } else {
    originPush.call(
      this,
      location,
      () => {},
      () => {}
    );
  }
};
//重写replace方法
VueRouter.prototype.replace = function (location, res, rej) {
  if (res && rej) {
    originReplace.call(this, location, res, rej);
  } else {
    originReplace.call(
      this,
      location,
      () => {},
      () => {}
    );
  }
};

const routes = [
  {
    path: "/",
    redirect: "/homePage", // 将根路径重定向到 '/homePage'
  },
  {
    path: "/scanCode",
    name: "scanCode",
    meta: { title: "扫一扫" },
    component: () => import("../views/scanCode.vue"),
    meta: {
      // 加一个自定义obj
      requireAuth: true, // 参数 true 代表需要登陆才能进入 A
    },
  },
  // 4G用水-扫码绑定
  {
    path: "/bindScanCode",
    name: "bindScanCode",
    meta: { title: "扫一扫" },
    component: () => import("../views/homePage/components/bindScanCode.vue"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/homePage",
    name: "homePage",
    meta: { title: "首页" },
    component: () => import("../views/homePage/index.vue"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/bindUse",
    name: "bindUse",
    meta: { title: "绑定用水" },
    component: () => import("../views/homePage/components/bindUse.vue"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/lyUseWater4G",
    name: "lyUseWater4G",
    meta: { title: "4G蓝牙用水" },
    component: () => import("../views/homePage/components/lyUseWater4G.vue"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/lySelectdevice",
    name: "lySelectdevice",
    meta: { title: "请选择一个4G蓝牙水表" },
    component: () => import("../views/homePage/components/lySelectdevice.vue"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/bingWaterVales",
    name: "bingWaterVales",
    meta: { title: "云热水" },
    component: () => import("../views/homePage/components/bingWaterVales.vue"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/bindCodeMeter",
    name: "bindCodeMeter",
    meta: { title: "扫码绑定" },
    component: () => import("../views/homePage/components/bindCodeMeter.vue"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/bindRoomMeter",
    name: "bindRoomMeter",
    meta: { title: "房间绑定" },
    component: () => import("../views/homePage/components/bindRoomMeter.vue"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/shareBlowDryer",
    name: "shareBlowDryer",
    meta: { title: "共享吹风机" },
    component: () => import("../views/homePage/components/shareBlowDryer.vue"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/shareWashPay",
    name: "shareWashPay",
    meta: { title: "支付页面" },
    component: () => import("../views/homePage/components/shareWashPay.vue"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/shareWashing",
    name: "shareWashing",
    meta: { title: "共享洗衣机" },
    component: () => import("../views/homePage/components/shareWashing.vue"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/recharge",
    name: "recharge",
    meta: { title: "充值" },
    component: () => import("../views/person/components/recharge.vue"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/useRecords",
    name: "useRecords",
    meta: { title: "使用记录" },
    component: () => import("../views/person/components/useRecords.vue"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/myBindMeter",
    name: "myBindMeter",
    meta: { title: "绑定水表" },
    component: () => import("../views/person/components/myBindMeter.vue"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/modifyData",
    name: "modifyData",
    meta: { title: "修改资料" },
    component: () => import("../views/person/components/modifyData.vue"),
    meta: {
      requireAuth: false,
    },
  },
  {
    path: "/realtimeCard",
    name: "realtimeCard",
    meta: { title: "我的任意卡" },
    component: () => import("../views/person/components/realtimeCard.vue"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/bindAnyCard",
    name: "bindAnyCard",
    meta: { title: "输入绑定任意卡" },
    component: () => import("../views/person/components/bindAnyCard.vue"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/bindAnyCardby4g",
    name: "bindAnyCardby4g",
    meta: { title: "4G绑定任意卡" },
    component: () => import("../views/person/components/bindAnyCardby4g.vue"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/doBindAnyCardby4g",
    name: "doBindAnyCardby4g",
    meta: { title: "远程4G绑卡" },
    component: () => import("../views/person/components/doBindAnyCardby4g.vue"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/repair",
    name: "repair",
    meta: { title: "设备报修" },
    component: () => import("../views/person/components/repair.vue"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/repairRecords",
    name: "repairRecords",
    meta: { title: "报修记录" },
    component: () => import("../views/person/components/repairRecords.vue"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/person",
    name: "person",
    meta: { title: "我的" },
    component: () => import("../views/person/index.vue"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/helpLi",
    name: "helpLi",
    meta: { title: "帮助中心" },
    component: () => import("../views/useHelp/helpLi.vue"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/helpOne",
    name: "helpOne",
    meta: { title: "4G物联网智能设备" },
    component: () => import("../views/useHelp/helpOne.vue"),
    meta: {
      requireAuth: true,
    },
  },
  // 处理二维码扫描后跳转到云闪付小程序的空白页面
  {
    path: "/handleScan",
    name: "handleScan",
    meta: { title: "扫码" },
    component: () => import("../views/handleScan.vue"),
    meta: {
      requireAuth: true,
    },
  },
];

const router = new VueRouter({
  routes,
});
router.beforeEach((to, from, next) => {
  // 判断是否云闪付环境
  var agent = navigator.userAgent.toLowerCase();
  var isInsideWallet =
    new RegExp(/(com.unionpay.chsp)/).test(agent) ||
    new RegExp(/(com.unionpay.mobilepay)/).test(agent);
  console.log(" agent ", agent);
  console.log("isInsideWallet", isInsideWallet);
  if (isInsideWallet) {
    //修改成云闪付登录
    upsdk.pluginReady(() => {
      upsdk.queryData({
        key: "token",
        success: function (result) {
          // result = {code:'',msg:'',key:'xxx',value:'xxx'}
          let token = result.value;
          if (to.meta.requireAuth) {
            if (!token) {
              // console.log("没有token信息，应该获取授权");
              // next({ path: '/homePage' })
              //云闪付一键登录
              // upsdk.appletAuth({
              //   success: function (d) {
              //     //  data ={code:’xxx’}
              //     console.log("code:" + d.code);
              //     let code = d.code;
              //     // 通过code获取用户信息
              //     let data = {
              //       code: code,
              //     };
              //     ysfLogin(data).then((res) => {
              //       console.log("登录返回的信息", res);
              //       if (res.code == 0) {
              //         console.log("登录成功");
              //         // console.log("需要存储的token", res.detail.token);
              //         const userInfoString = JSON.stringify(res.detail.userInfo);
              //         upsdk.saveData({
              //           key: "token",
              //           value: res.detail.token,
              //           success: function (result) {
              //             // result = {code:'',msg:''}
              //             console.log("存储token结果", res.detail.token);
              //           },
              //         });
              //         upsdk.saveData({
              //           key: "userInfo",
              //           value: userInfoString ,
              //           success: function (result) {
              //             // result = {code:'',msg:''}
              //           },
              //         });
              //         window.token = res.detail.token;
              //       } else {
              //         console.log("登录失败");
              //       }
              //     });
              //   },

              //   fail: function (error) {
              //     // error={errcode:’xxx’, errmsg:’xxx’}
              //   },
              // });
              next();
            } else {
              // console.log("有token信息，应该保存token");
              // window.token = token;
              next();
            }
          } else {
            next();
          }
        },
      });
    });
  } else {
    // 非云闪付环境
    Dialog.alert({
      title: "提示",
      message: "请使用云闪付APP打开该应用",
    });
    return;
  }
});

export default router;
