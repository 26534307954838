import axios from "../axios";
import utils from "../util";
import { Dialog } from "vant";

// 包装器函数，用于处理自定义参数逻辑
const withCustomParams = (requestFunction) => {
  return (params) => {
    const signObject = utils.mySignObject(params);
    const { random, timeStamp, signature } = signObject;
    const newParams = {
      ...params,
      random,
      timeStamp,
      signature,
    };
    return requestFunction(newParams);
  };
};

// 获取个人信息
export const getMyInfo = withCustomParams((params) => {
  //   Dialog.alert({
  //     title: "提示参数",
  //     message: params,
  //   }).then(() => {});
  return axios({
    url: "/user/GetMyInfo",
    method: "get",
    params,
  });
});
// 个人房号
export const getRoomNum = withCustomParams((params) => {
  return axios({
    url: "/Index/GetRoomNum",
    method: "get",
    params,
  });
});

// 获取账户余额
export const getMyBalance = withCustomParams((params) => {
  return axios({
    url: "/user/GetMyBalance",
    method: "get",
    params,
  });
});
// 获取充值记录
export const getRechargeLog = withCustomParams((params) => {
  return axios({
    url: "/user/GetRechargeLog",
    method: "get",
    params,
  });
});
// 获取充值金额
export const getRechargePriceList = withCustomParams((params) => {
  return axios({
    url: "/Index/GetRechargePriceList",
    method: "get",
    params,
  });
});
// 预充值得到单号
export const checkoutRechargeOrder = (data) => {
  return axios({
    url: "/User/CheckoutRechargeOrder/",
    method: "post",
    data,
  });
};
// // 充值
// export const getRechargePriceList = withCustomParams((params) => {
//     return axios({
//         url: '/Index/GetRechargePriceList',
//         method: 'get',
//         params
//     });
// });

// 日免额、补贴金额
export const getMyFreeQuota = withCustomParams((params) => {
  return axios({
    url: "/User/GetMyFreeQuota",
    method: "get",
    params,
  });
});
// 充值记录
export const getNoCardWaterUseLog = withCustomParams((params) => {
  return axios({
    url: "/User/GetNoCardWaterUseLog",
    method: "get",
    params,
  });
});
// 我的绑定水表
export const getMyBindDeviceList = withCustomParams((params) => {
  return axios({
    url: "/User/GetMyBindDeviceList",
    method: "get",
    params,
  });
});
// 删除-我的绑定水表
export const doDelBindDevice = withCustomParams((params) => {
  return axios({
    url: "/User/DoDelBindDevice",
    method: "get",
    params,
  });
});
// 绑定-我的绑定水表
export const doBindDevice = withCustomParams((params) => {
  return axios({
    url: "/User/DoBindDevice",
    method: "get",
    params,
  });
});
// 修改资料
export const doReg = (data) => {
  return axios({
    url: "/User/DoReg",
    method: "post",
    data,
  });
};
// 报修-上传图片
export const uploadImg = (data) => {
  return axios({
    url: "/User/UploadImg",
    method: "post",
    data,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
// 报修
export const postRepairInfo = (data) => {
  return axios({
    url: "/User/PostRepairInfo",
    method: "post",
    data,
  });
};
// 报修记录
export const getRepairList = withCustomParams((params) => {
  return axios({
    url: "/User/GetRepairList",
    method: "get",
    params,
  });
});
//
export const getCfg = withCustomParams((params) => {
  return axios({
    url: "/Index/GetCfg",
    method: "get",
    params,
  });
});

// 签约
export const SignContract = withCustomParams((params) => {
  return axios({
    url: "/UnionPayApp/SignContract",
    method: "get",
    params,
  });
});
// 未支付账单
export const DoCheckoutBillOrder = withCustomParams((params) => {
  return axios({
    url: "/user/DoCheckoutBillOrder",
    method: "get",
    params,
  });
});
// 充值
export const DoPayAndSign = withCustomParams((params) => {
  return axios({
    url: "/UnionPayApp/DoPayAndSign",
    method: "get",
    params,
  });
});
// 签约成功后
export const QuerySignContract = withCustomParams((params) => {
  return axios({
    url: "/UnionPayApp/QuerySignContract",
    method: "get",
    params,
  });
});
// 支付成功后
export const DoCheckPayResult = withCustomParams((params) => {
  return axios({
    url: "/UnionPayApp/DoCheckPayResult",
    method: "get",
    params,
  });
});
// 是否签约
export const CheckUserIsSign = withCustomParams((params) => {
  return axios({
    url: "/UnionPayApp/CheckUserIsSign",
    method: "get",
    params,
  });
});
// 解约
export const TerminationContract = withCustomParams((params) => {
  return axios({
    url: "/UnionPayApp/TerminationContract",
    method: "get",
    params,
  });
});
// 获取任意卡绑卡列表
export const GetMyBindDeviceList = withCustomParams((params) => {
  return axios({
    url: "User/GetMyBindDeviceList",
    method: "get",
    params,
  });
});
// 解绑任意卡
export const DoDelMyRealtimeAnyCard = withCustomParams((params) => {
  return axios({
    url: "/User/DoDelMyRealtimeAnyCard",
    method: "get",
    params,
  });
});
// 读取卡片信息
export const GetCIBy4G = withCustomParams((params) => {
  return axios({
    url: "/user/GetCIBy4G",
    method: "get",
    params,
  });
});
// 确认绑定卡片 三种
export const DoBindCard = withCustomParams((params) => {
  return axios({
    url: "/User/DoBindCard",
    method: "get",
    params,
  });
});
// 绑定任意卡-卡号
export const DoBindRealtimeAnyCard = withCustomParams((params) => {
  return axios({
    url: "/User/DoBindRealtimeAnyCard",
    method: "get",
    params,
  });
});
export const DoBindVirtualCPUCard = withCustomParams((params) => {
  return axios({
    url: "/User/DoBindVirtualCPUCard",
    method: "get",
    params,
  });
});
